import {base_url} from './baseurl';


const auth_cred ={
    email: "admin@gmail.com",
    password: "123456"
}
export function login(data){
    
    return fetch(base_url+'login',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "accept":"application/json"
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}
export function register(data){
    
    return fetch(base_url+'register',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
        },
        body:JSON.stringify(data)
    })
    .then(res=>res.json())
   
}

export function sendOtp(email){
    
    return fetch(base_url+'request_otp?email='+email,{
        method:'GET',
        headers:{
            "Content-Type":"application/json",
        },
    
       
    })
    .then(res=>res.json())
   
}
export function verifyOtp(data){
    
    return fetch(base_url+'verify_otp',{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
        },
        body:JSON.stringify(data)
    
       
    })
    .then(res=>res.json())
   
}
export function getUser(){
    
    return fetch(base_url+'getUser',{
        method:'GET',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
    
    
       
    })
    .then(res=>res.json())
   
}
export function updateUser(data){
    
    return fetch(base_url+'updateUser',{
        method:'PUT',
        headers:{
            "Authorization":localStorage.getItem("token"),
            "Content-Type":"application/json",
        },
        body:JSON.stringify(data)
    
       
    })
    .then(res=>res.json())
   
}