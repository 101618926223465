import {  Backdrop, CircularProgress, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { getCompanies } from '../../apis/Company';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CompaniesTable from '../../components/tables/CompaniesTable';
import { getLogs } from '../../apis/Log';
import LogsTable from '../../components/tables/LogTable';

const Log = (props) => {

    const [logs,set_logs] = useState([]);
    const [logs_data,set_logs_data] = useState([]);

    const [loading,setLoading] = useState(true);
    const [page,setpage] = useState(1);


    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        set_logs([]);
        set_logs_data([]);

        setpage(value);
        
        getLogs(value)
        .then(data=>{
            console.log(data.data);
            set_logs(data.data.data);
            set_logs_data(data.data);
            setLoading(false);
        })
        
    }
    const navigate=  useNavigate();
    useEffect(()=>{
        getLogs(1)
        .then((data)=>{
            if(data.success){
                set_logs(data.data.data);
                set_logs_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })
    },[]);

 
    return (
        <DashboardContent title="Logs">
            <Paper elevation={2} >
                <Box p={2}>
                 <LogsTable logs={logs} logs_data={logs_data} handleChangePage={handleChangePage}/>
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            
        </DashboardContent>
    );
}

export default Log;