import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';
import {Link as RouteLink} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import themeDataCustom from '../../Theme/ThemeDataCustom';
import { login } from '../../apis/Auth';
import {  toast,ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import PublicLayout from '../../layouts/PublicLayout';

const theme = createTheme(themeDataCustom());

export default function Login() {

  const [loading,setLoading] = useState(false);
  

  const navigate = useNavigate();
   
  const handleSubmit = (event) => {
  
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    
    if(data.get('email')==""){
      toast.error("Email is required");
      setLoading(false);
      return false;
    }
    else if(data.get('password')==""){
      toast.error("Password is required");
      setLoading(false);
      return false;
    }
    else if(data.get('email')){
      var validRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!data.get('email').match(validRegex)) {
        toast.error("Email is not valid");
        setLoading(false);
        return false;
      }
    }
    
    let data_api = {
      email: data.get('email'),
      password: data.get('password'),

    };
    
   
   login(data_api)
   .then((data)=>{
    setLoading(false);
    if(data.success){
      
       if(data.data.user.email_verified_at!=null){
        localStorage.clear();
        localStorage.setItem("token","Bearer "+data.data.token);
        localStorage.setItem("user",data.data.user);
        localStorage.setItem("username",data.data.user.name);

        navigate("/");
       }else{
        toast.error("Account is not verified yet!");
       }
        
    }else{
      toast.error("Email/Password are wrong!");
    }
    
  })

    


    

  };
  

  return (
    <PublicLayout>
      <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
        </Backdrop>
      <ToastContainer/>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Login
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
       
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"

                  autoComplete="new-password"
                />
              </Grid>
            
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Grid container justifyContent="flex-end">
            <Grid item xs={6} align="left">
                <Link component={RouteLink}to="/forget"  variant="body2">
                  Forget Password?
                </Link>
              </Grid>
              <Grid item xs={6} align="right">
                <Link  component={RouteLink}to="/register" variant="body2">
                  New ? Create an account
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      
    </PublicLayout>
  );
}