import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';
import {Link as RouteLink} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {  toast,ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import themeDataCustom from '../../Theme/ThemeDataCustom';
import { useState } from 'react';
import { sendOtp } from '../../apis/Auth';
import { Backdrop, CircularProgress } from '@mui/material';
import PublicLayout from '../../layouts/PublicLayout';

const theme = createTheme(themeDataCustom());

export default function Forget() {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  
  const handleSubmit = (event) => {

    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    if(data.get('email')==""){
      toast.error("Email is required");
      setLoading(false);
      return false;
    }
    else if(data.get('email')){
      var validRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!data.get('email').match(validRegex)) {
        toast.error("Email is not valid");
        setLoading(false);
        return false;
      }
    }
    setLoading(true);
    sendOtp(data.get('email'))
   .then((data)=>{
    setLoading(false);
    if(data.success){
      
       
        localStorage.clear();
        localStorage.setItem("verify_username",data.data);
        navigate("/verify");
        
    }else{
      toast.error("Email is not valid!");
    }
  });
}
  

  return (
    <PublicLayout>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
        </Backdrop>
      <ToastContainer/>
    
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
          }}
        >
        <Box sx={{

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar  sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <HelpIcon />
          </Avatar>
          </Box>
          <Typography align="center" component="h1" variant="h5">
           Forget Password
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
       
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
              </Grid>
            
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send Verification Code
            </Button>
            <Grid container justifyContent="flex-end">
         
              <Grid item xs={6} align="right">
                <Link  component={RouteLink}to="/login" variant="body2">
                  Back to Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      
        </PublicLayout>
  );
}