import * as React from 'react';

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import themeDataCustom from '../Theme/ThemeDataCustom';
import Box from '@mui/material/Box';
import PublicHeader from '../layouts/PublicHeader';

const theme = createTheme(themeDataCustom());

export default function PublicLayout(props) {

  
  

  return (
    <ThemeProvider theme={theme}>
      <PublicHeader/>
      <Container component="main" maxWidth="xs">
      <Box p={2}/>
        {props.children}
        <Box p={2}/>
      </Container>
    </ThemeProvider>
  );
}