import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Deposits from '../../components/Deposits';
import { Backdrop, CircularProgress } from '@mui/material';


const Dashboard =(props)=>{
  
  const [loading,setLoading]=useState(false)
  
    return (
        <DashboardContent title="Dashboard">
            
        
            <Grid container spacing={3}>
          
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits />
                </Paper>
              </Grid>
             </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </DashboardContent>
    );
}
export default Dashboard;