import {base_url} from './baseurl';



export function getXmls(page,id){
    
    
    return fetch(base_url+'xml/'+id+'?page='+page,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}

export function saveXml(data){

    
    return fetch(base_url+'xml',{
       method:'POST',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:data

      
   })
   .then(res=>res.json())
  
}
export function deleteXml(id){

    
    return fetch(base_url+'xml/'+id,{
       method:'DELETE',
       headers:{
        //    "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       }

      
   })
   .then(res=>res.json())
  
}

export function processXml(data){
    
    
    return fetch(base_url+'processXml',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
       body:data
   })
   .then(res=>res.json())
  
}

