import {base_url} from './baseurl';



export function getLogs(page){
    
    
    return fetch(base_url+'logs/?page='+page,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}