import { Backdrop, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {React,useEffect,useState} from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { useNavigate } from 'react-router-dom';

import {  toast } from 'react-toastify';
import { saveCompany } from '../../apis/Company';

const NewCompany = () => {
    const navigate = useNavigate();


    const [loading,setLoading] = useState(false);

    

    const [errors,setErrors] = useState([]);






    const [name,set_name] = useState('');
    const [url,set_url] = useState('');
    const [token,set_token] = useState("");






    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "name":
                set_name(event.target.value);
                break;
            case "token":
                set_token(event.target.value);
                break;
            case "url":
                set_url(event.target.value);
                break;
            default: break;
        }
    }
    
    
    const handleSubmit = (event)=>{
        
        setLoading(true);
      
       let data = {
            name:name,
            url:url,
            token:token,
        }

        saveCompany(data)
        .then((data)=>{
            
        setLoading(false);
        if(data.success){
            toast.success(data.message);
            navigate("/company/edit/"+data.data.id)
         
        }else{
            
            if(data.data!=undefined){
           setErrors(data.data);
            }else{
                toast.error(data.message);
            }

        }
    })

        
    }

    return (
        <DashboardContent title="Company">


            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                New Company
                            </Typography>

                         
                           

                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>

                             
                                    <FormControl fullWidth>

                                    <TextField  required label="Name" variant="outlined" InputLabelProps={{ shrink: true }} name="name" onChange={handleChange} value={name} 
                                        error={errors.name!=undefined?true:false}
                                        helperText={errors.name!==undefined?errors.name:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth>

                                    <TextField  required type="text" label="URL" variant="outlined" InputLabelProps={{ shrink: true }} name="url" onChange={handleChange} value={url} 
                                        error={errors.url!=undefined?true:false}
                                        helperText={errors.url!==undefined?errors.url:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                    <FormControl fullWidth>

                                    <TextField required  type="text" label="Token" variant="outlined" InputLabelProps={{ shrink: true }} name="token" onChange={handleChange} value={token} 
                                        error={errors.token!=undefined?true:false}
                                        helperText={errors.token!==undefined?errors.token:''}
                                        />

                                    </FormControl>
                                    <Box mb={2} />
                                

                                </Grid>
                             
                                </Grid>
                           
                            
                            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 4 }}>

                                <Grid item xs={12} sm={12} md={6}>
                                <FormControl >
                                            <Button variant="outlined"   onClick={handleSubmit} >Save Company</Button>
                                </FormControl>
                                </Grid>
                            </Grid>





                                   

                    

                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </DashboardContent>
    );
}

export default NewCompany;