import { Backdrop, Button, CircularProgress, FormControl, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React,{useEffect, useState} from 'react';
import { getUser, updateUser } from '../../apis/Auth';
import { useNavigate } from 'react-router-dom';
import DashboardContent from '../../layouts/DashboardLayout';
import {  toast,ToastContainer } from 'react-toastify';

const Account = () => {
    
    const navigate = useNavigate();
    const [email,setEmail] = useState("");
    const [error,setError] = useState([]);
  


    const [new_password,set_new_password] = useState("");
    const [confirm_new_password,set_confirm_new_password] = useState("");
    const [current_password,set_current_password] = useState("");



     

    const [loading,setLoading] = useState(true);

    const handleChange=(event)=>{
        event.preventDefault();
   
        switch(event.target.name){
            case "email":
                setEmail(event.target.value);
                break;
            case "new_password":
                set_new_password(event.target.value);
                break;
            case "confirm_new_password":
                set_confirm_new_password(event.target.value);   
                break;
            case "current_password":
                set_current_password(event.target.value);
                break;
            default:break;

        }
    }

  
    useEffect(()=>{
        getUser()
        .then((data)=>{
            setLoading(false);
            if(data.success){

                setEmail(data.data.email);

            }else{
                localStorage.clear();
                navigate('/')
            }
        })
       
    },[])
    const handleSubmit=(event)=>{
        event.preventDefault();
        if(confirm_new_password !== new_password){
            toast.error("password and password confirmation doesn't match!")
            return false;
        }

        let password_new = "";
        if(confirm_new_password !="" || new_password!=""){
             password_new = confirm_new_password;
        }


    
        if(email==""){
          toast.error("Email is required");
          setLoading(false);
          return false;
        }
     
        else if(email){
          var validRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
          if (!email.match(validRegex)) {
            toast.error("Email is not valid");
            setLoading(false);
            return false;
          }
        }
        
        if(new_password !="" && confirm_new_password!=""){
            if(current_password==""){
                toast.error("Please enter current password");
            }
        }

        let data={
            old_password: current_password,
            new_password : password_new,
            email : email
        }
        setLoading(true)
        updateUser(data)
        .then((data)=>{
        setLoading(true)

            setLoading(false);
            if(data.success){
                if(data.data.no_changes!= undefined && data.data.no_changes){
                    toast.success('No changes found!')
                }else{
                localStorage.clear();
                localStorage.setItem("verify_username",email);
                navigate('/verify');
                }
            }else{
                if(data.data.email!=undefined){
                toast.error(data.data.email[0])
                }else{
                    toast.error(data.message)
                }

            }
        })



        
    }

    return (
        <DashboardContent title="Your Account">

        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer/>
            <Grid  container rowSpacing={4} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={12} sm={12} md={6}>
                    <Paper elevation={2} >
                        <Box p={2}>
                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                mb={4}
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Account Credentials
                            </Typography>
                            <FormControl fullWidth >

                                <TextField label="Email" type="email" variant="outlined" onChange={handleChange} value={email} name="email" InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />

                            <FormControl fullWidth>

                                <TextField label="Password" type="password" name="new_password" value={new_password} variant="outlined" onChange={handleChange} InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />

                            <FormControl fullWidth>

                                <TextField label="Password Confirmation" name="confirm_new_password" onChange={handleChange} value={confirm_new_password} type="password" variant="outlined" InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />

                            <FormControl fullWidth>

                                <TextField label="Current Password" name="current_password" type="password" value={current_password} onChange={handleChange} variant="outlined" helperText="We need your current password to confirm your changes" InputLabelProps={{ shrink: true }} />

                            </FormControl>
                            <Box mb={2} />

                            <FormControl >

                                <Button onClick={handleSubmit} variant="contained">Update</Button>

                            </FormControl>

                        </Box>
                    </Paper>
                </Grid>
             
            </Grid>


        </DashboardContent>
    );
}

export default Account;