import {  Backdrop, CircularProgress, Fab, Paper } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import DashboardContent from '../../layouts/DashboardLayout';
import { getXmls } from '../../apis/Xml';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import XmlsTable from '../../components/tables/XmlTable';

const Xmls = (props) => {
    const params = useParams();
    const [xmls,set_xmls] = useState([]);
    const [xmls_data,set_xmls_data] = useState([]);
    const [page,setpage] = useState(1);
    const [loading,setLoading] = useState(true);



    const navigate=  useNavigate();

    const handleChangePage=(event,value)=>{
       
        setLoading(true);
        set_xmls([]);
        set_xmls_data([]);

        setpage(value);
        
        getXmls(value,params.id)
        .then(data=>{
            // console.log(data.data);
            set_xmls(data.data.data);
            set_xmls_data(data.data);
            setLoading(false);
        })
        
    }
    useEffect(()=>{
        getXmls(1,params.id)
        .then((data)=>{
            if(data.success){
                set_xmls(data.data.data);
                set_xmls_data(data.data);
            }else{
                navigate('/');
            }
            setLoading(false);


        })
    },[]);

 
    return (
        <DashboardContent title="Your XMLS">
            <Paper elevation={2} >
                <Box p={2}>
                 <XmlsTable xmls={xmls} xmls_data={xmls_data} handleChangePage={handleChangePage}/>
                </Box>
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Fab color="secondary" aria-label="edit"  onClick={()=>{navigate('/company/xml/'+params.id)}} 
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(5)
            }}>
                <AddIcon />
            </Fab>
        </DashboardContent>
    );
}

export default Xmls;