import {base_url} from './baseurl';

export function getCompanies(page,data=""){
    
    
    return fetch(base_url+'company/?page='+page+'&'+new URLSearchParams(data),{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token"),
           
        //    "X-Requested-With": "XMLHttpRequest",
       },
   })
   .then(res=>res.json())
  
}


export function saveCompany(data){

    
    return fetch(base_url+'company',{
       method:'POST',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}

export function updateCompany(data,id){

    
    return fetch(base_url+'company/'+id,{
       method:'PUT',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },
       body:JSON.stringify(data)

      
   })
   .then(res=>res.json())
  
}

export function deleteCompany(id){

    
    return fetch(base_url+'company/'+id,{
       method:'DELETE',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },

      
   })
   .then(res=>res.json())
  
}

export function getCompanyById(id){

    
    return fetch(base_url+'company/'+id,{
       method:'GET',
       headers:{
           "Content-Type":"application/json",
           "Authorization":localStorage.getItem("token")
       },

      
   })
   .then(res=>res.json())
  
}