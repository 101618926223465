import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';
import FolderIcon from '@mui/icons-material/Folder';
import moment from 'moment/moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { Backdrop, CircularProgress, Grid, IconButton, Pagination, TableContainer, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteCompany } from '../../apis/Company';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { deleteXml, processXml } from '../../apis/Xml';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';


export default function XmlsTable(props) {

    const [loading,setLoading] = useState(false);

    
    const xmls = props.xmls;
    const xmls_data = props.xmls_data;
    const page = props.page;
    return (

        <React.Fragment >
                <ToastContainer/>
            
            
            <Typography
                component="h2"
                variant="h6"
                color="inherit"
                mb={4}
                noWrap
                sx={{ flexGrow: 1 }}
            >
                XMLS 
            </Typography>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>File Path</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Process XML</TableCell>
                            <TableCell></TableCell>



                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {xmls.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.path}</TableCell>
                                <TableCell>{moment(row.created_at).format("DD/MM/YYYY hh:mm:ss a")}</TableCell>
                                <TableCell>
                                    {!row.processed ? (
                                        <IconButton  onClick={()=>{
                                            setLoading(true);
                                            let data = {id:row.id}
                                            processXml(JSON.stringify(data))
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    toast.success(data.message);

                                                }else{
                                                    toast.error(data.message);


                                                }
                                            })

                                        }}>
                                            <AccountTreeTwoToneIcon color="primary"/>
                                    </IconButton>
                                    ):''}
                                    
                                </TableCell>
                                {/* <TableCell>{ row.processed ? (<CheckCircleRoundedIcon/>):''}</TableCell> */}
                                <TableCell>
                         
                                   <IconButton onClick={()=>{
                                        let confirm_delete  =  window.confirm("Do you want to delete this record");
                                        if(confirm_delete){
                                            setLoading(true);
                                            deleteXml(row.id)
                                            .then((data)=>{
                                                setLoading(false);
                                                if(data.success){
                                                    window.location.reload();
                                                }else{
                                                    toast.error(data.message);
                                                }
                                            })
                                        }
                                    }}>
                                        <DeleteIcon color="warning" />
                                    </IconButton>
                             

                                </TableCell>



                            </TableRow>
                        ))}
                      
                    </TableBody>
                    
                </Table>
                <Grid
                mt={3}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" > 
                <Grid item >
                <Pagination
                  count= {xmls_data.last_page}
                  page={page}
                  onChange={(event, pageNumber) => props.handleChangePage(event, pageNumber)}

                 color="primary" />
                </Grid>
                </Grid>
            </TableContainer>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{return ;}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}