import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Dashboard from  './Pages/home/Dashboard';
import Company from './Pages/company/Company';
import NewCompany from './Pages/company/NewCompany';
import Account from './Pages/account/Account';

import Login from './Pages/auth/Login';
import Forget from './Pages/auth/Forget';
import ResetPassword from './Pages/auth/ResetPassword';
import Register from './Pages/auth/Register';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';

import Verify from './Pages/auth/Verify';
import EditCompany from './Pages/company/EditCompany';
import StoreXml from './Pages/company/StoreXml';

import Xmls from './Pages/company/Xmls';
import Logs from './Pages/logs/Log';

function App() {
  return (
    <Router>
        <Routes>


         
        <Route path="/verify" element={<PublicRoute/>}>
            <Route  path="/verify" element={<Verify/>}/>
          </Route>


          <Route path="/login" element={<PublicRoute/>}>
            <Route  path="/login" element={<Login/>}/>
          </Route>

          <Route path="/register" element={<PublicRoute/>}>
            <Route  path="/register" element={<Register/>}/>  
          </Route>

          
          <Route path="/forget" element={<PublicRoute/>}>
            <Route  path="/forget" element={<Forget/>}/>      
          </Route>

          <Route path="/reset" element={<PublicRoute/>}>
            <Route  path="/reset" element={<ResetPassword/>}/>      
          </Route>


          <Route path="/" element={<PrivateRoute/>}>
            <Route  path="/" element={<Dashboard/>}/> 
          </Route>   
          
          <Route path="/account" element={<PrivateRoute/>}>
            <Route  path="/account" element={<Account/>}/>    
          </Route>
          
          
        

          <Route path="/company" element={<PrivateRoute/>}>
            <Route  path="/company" element={<Company/>}/>    
          </Route>

        

          <Route path="/company/add" element={<PrivateRoute/>}>
            <Route  path="/company/add" element={<NewCompany/>}/>    
          </Route>

        
          <Route path="/company/edit/:id" element={<PrivateRoute/>}>
            <Route  path="/company/edit/:id" element={<EditCompany/>}/>    
          </Route>

          <Route path="/company/xml/cp/:id" element={<PrivateRoute/>}>
            <Route  path="/company/xml/cp/:id" element={<Xmls/>}/>    
          </Route>

          <Route path="/company/xml/:id" element={<PrivateRoute/>}>
            <Route  path="/company/xml/:id" element={<StoreXml/>}/>    
          </Route>

          
          <Route path="/logs" element={<PrivateRoute/>}>
            <Route  path="/logs" element={<Logs/>}/>    
          </Route>



    
       


         

       
        </Routes>
   
    </Router>
  );
}

export default App;
